<template>
  <div>
    <section id="mt_blog" class="fit-goal">
      <div class="container">
        <div class="category-item">
          <div class="category-item-inner">
            <div class="row">
              <div class="col-md-6 col-sm-12" data-aos="zoom-in-up">
                <div class="section-title">
                  <h2>{{ $t("Main.elon") }}</h2>
                </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12 mar-bottom-30">
                    <div
                      v-for="(elon, i) in elons"
                      :key="i"
                      class="blog-post_wrapper image-wrapper"
                    >
                      <div class="blog-post-image">
                        <img
                          :src="
                            'https://backend.scocenter.uz/storage/' + elon.image
                          "
                          alt="image"
                          height="200px"
                          class="img-responsive center-block post_img"
                        />
                      </div>
                      <div class="post-content" style="color: #3498DB">
                        <div>
                          <h4>
                            <router-link :to="'/adds-detail/' + elon.id">
                              {{ elon[`title_${$i18n.locale}`] | kes }}
                            </router-link>
                          </h4>
                        </div>
                        <div
                          style="display: flex; margin-top: 2%; margin-bottom: 2%"
                        >
                          <div>
                            <strong>
                              <i class="fa fa-calendar"></i
                              >{{ $t("start") }}</strong
                            >
                            <span>{{ elon.ads_start }}</span>
                          </div>
                          <div style="margin-left: 10%">
                            <strong
                              ><i class="fa fa-calendar"></i
                              >{{ $t("finish") }}</strong
                            >
                            <span>{{ elon.ads_end }}</span>
                          </div>
                        </div>
                        <div class="entry-meta-content">
                          <p>{{ elon[`description_${$i18n.locale}`] }}</p>
                        </div>
                        <!--./ entry-meta-content -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" data-aos="zoom-in-up">
                <div class="section-title">
                  <h2>{{ $t("Main.most") }}</h2>
                </div>
                <article
                  v-for="(post, i) in posts3"
                  :key="i"
                  class="post-list-main mar-bottom-30"
                >
                  <div class="entry-thumb">
                    <div class="thumb-wrap">
                      <img
                        :data-src="
                          'https://backend.scocenter.uz/storage/' + post.image
                        "
                        alt="image"
                        class="img-responsive center-block post_img lazyload blur-up"
                      />
                    </div>
                    <!--./ thumb-wrap -->
                  </div>
                  <!--./ entry-thumb -->
                  <div class="content-entry-wrap">
                    <div class="entry-content">
                      <h4
                        class="entry-title mar-bottom-5"
                        style="font-size: 13px; color: #3498DB"
                      >
                        <router-link :to="'/post-detail/' + post.id">{{
                          post[`title_${$i18n.locale}`] | kes
                        }}</router-link>
                      </h4>
                      <!--./ entry-title -->
                    </div>
                    <!--./ entry-content -->
                    <div class="entry-meta-content">
                      <div class="entry-date">
                        <span style="font-size: 13px; color: #3498DB">
                          {{ post.created_at | slice }}
                        </span>
                        <span
                          style="font-size: 13px; margin-right: 10px; color: #3498DB; float: right"
                        >
                          <i class="fa fa-eye" style="margin-right: 7px"></i
                          >{{ post.views_count }}</span
                        >
                      </div>
                      <!--./ entry-date -->
                    </div>
                    <!--./ entry-meta-content -->
                  </div>
                  <!--./ content-entry-wrap -->
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "../apis";
import "aos/dist/aos.css";
export default {
  name: "cate",
  data() {
    return {
      posts3: "",
      elons: [],
      cate: [],
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api.get("/count").then((response) => {
          this.posts3 = response.data.count.slice(0, 4);
        });
      } catch (error) {
        console.error(error);
      }
      try {
        const res = await api.get("/notification").then((response) => {
          this.elons = response.data.notification.slice(0, 1);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
.post-list-main .content-entry-wrap a,
.category-item .content-entry-wrap a {
  color: #3498db;
  padding-bottom: 5px;
  background-image: linear-gradient(to right, #3498db, #3498db);
}
a {
  background: linear-gradient(to right, #3498db, #3498db);
  color: #3498db;
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  background-size: 0px 3px;
  padding-bottom: 10px;
}
a:hover {
  text-decoration: none !important;
  background-size: 100% 3px;
  transition: all ease-in-out 0.5s;
}
h2 {
  color: #3498db;
  margin: 0;
  position: relative;
  font-weight: 800;
  font-size: 22px;
  margin-bottom: 8px;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7 !important;
  max-height: none !important;
}
.bg-orange {
  margin: 0;
  background: #3498db;
}
.breadcrumb-outer {
  padding: 64px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 70px 0 5px !important;
  font-size: 35px;
}
.d-flex {
  display: flex;
  flex-direction: column;
}
#mt_blog .blog-post_wrapper {
  overflow: inherit;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  box-shadow: 0 0 15px #cccccc60;
}

#mt_blog .blog-post_wrapper.image-wrapper:before {
  display: none;
}

#mt_blog .blog-post_wrapper .blog-post-image {
  overflow: hidden;
  position: relative;
  height: 300px;
}
#mt_blog .blog-post_wrapper .blog-post-image:before {
  content: "";
  background: #00000057;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

#mt_blog .blog-post_wrapper.image-wrapper .post-content {
  position: relative;
  top: inherit;
  bottom: 0;
  padding: 15px;
}

#mt_blog .blog-post_wrapper h3 span {
  font-size: 30px;
  text-transform: uppercase;
  display: block;
}

section#mt_blog.category-list {
  background: url(/images/fitness/sliders/bg9.jpg) no-repeat;
  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;
}

section#mt_blog.category-list:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: #00000070;
  top: 0;
  height: 100%;
  width: 100%;
}
/* li a{
    background: #3498DB;
} */
section#mt_blog.category-list .blog-post_wrapper.image-wrapper .post-content {
  background: #fff;
  padding: 15px;
}

section#mt_blog.category-list .row.slider-foods {
  margin: 0 -15px;
}
</style>
