<template>
  <div>
    <!-- travel/sliders -->
    <section
      id="mt_banner"
      class="lazyload blur-up"
      data-bgset="/assets/images/Home/bga.webp"
    >
      <div class="swiper-container">
        <div class="banner-title text-center">
          <h2 class="white mar-bottom-30">{{ $t("Shanghai") }}</h2>
        </div>
        <!-- <div class="swiper-button-next swiper-button-" style="background: transparent"></div>
                <div class="swiper-button-prev swiper-button-"></div> -->
      </div>
    </section>
    <!-- End travel/sliders -->
  </div>
</template>
<script>
import api from "../apis";
export default {
  name: "Slider",
  data() {
    return {
      posts1: "",
      posts2: "",
      posts3: "",
      posts4: "",
      posts5: "",
      posts6: "",
      cate: "",
      created_at1: "",
      created_at2: "",
      created_at3: "",
      created_at4: "",
      created_at5: "",
      created_at6: "",
      i: [1, 2, 3, 4, 5, 6],
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api.get("/post").then((response) => {
          // console.log(response)
          this.posts1 = response.data.post[0];
          this.posts2 = response.data.post[1];
          this.posts3 = response.data.post[2];
          this.posts4 = response.data.post[3];
          this.posts5 = response.data.post[4];
          this.posts6 = response.data.post[5];
          this.created_at1 = response.data.post[0].created_at.slice(0, 10);
          this.created_at2 = response.data.post[1].created_at.slice(0, 10);
          this.created_at3 = response.data.post[2].created_at.slice(0, 10);
          this.created_at4 = response.data.post[3].created_at.slice(0, 10);
          this.created_at5 = response.data.post[4].created_at.slice(0, 10);
          this.created_at6 = response.data.post[5].created_at.slice(0, 10);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {},
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
#mt_banner {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center !important;
  background-attachment: fixed;
}
.swiper-button-prev:before,
.swiper-container-rtl .swiper-button-next:before {
  content: "\f104";
  left: 15px;
  right: inherit;
  background: transparent;
}
.swiper-button-next:before,
.swiper-container-rtl .swiper-button-next:before {
  background: transparent;
}
@media (max-width: 567px) {
  h2 {
    margin-top: 30px;
  }
}
@media (min-width: 1300px) {
  #mt_banner {
    min-height: 750px !important;
    max-height: 966px !important;
  }
}
@media (min-width: 1500px) {
  #mt_banner {
    min-height: 850px !important;
    max-height: 966px !important;
  }
}
@media (min-width: 1700px) {
  #mt_banner {
    min-height: 950px !important;
    max-height: 966px !important;
  }
}
</style>
