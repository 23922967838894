import Vue from 'vue'
import VueRouter from 'vue-router'
import Contact from '../views/ContactUs.vue'
import VueMeta from 'vue-meta'
import Home from "../views/Home.vue"
Vue.use(VueMeta)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/post-detail/:id',
    name: 'PostDetail',
    component: () => import (/* webpackChunkName: “Photos” */ '../views/PostDetail.vue')
  },
  {
    path: '/news-category/:id',
    name: 'Newscate',
    component: () => import (/* webpackChunkName: “Newscate” */'../views/Newscat.vue')
  },
  {
    path: '/photo-detail/:id',
    name: 'PhotoDetail',
    component: () => import (/* webpackChunkName: “PhotoDetail” */'../views/PhotoDetail.vue')
  },
  {
    path: '/adds-detail/:id',
    name: 'AddDetail',
    component: () => import (/* webpackChunkName: “AddDetail” */'../views/AddDetail.vue')
  },
  {
    path: '/about-center',
    name: 'AboutCenter',
    component: () => import (/* webpackChunkName: “AboutCenter” */'../views/AboutCenter.vue')
  },
  {
    path: '/personnel',
    name: 'Structure',
    component: () => import (/* webpackChunkName: “Structure” */'../views/Structure.vue')
  },
  {
    path: '/structure',
    name: 'Structur',
    component: () => import (/* webpackChunkName: “Structur” */'../views/Structur.vue')
  },
  {
    path: '/manual',
    name: 'Manual',
    component: () => import (/* webpackChunkName: "Manual” */'../views/Manual.vue')
  },
  {
    path: '/documents',
    name: 'Docs',
    component: () => import (/* webpackChunkName: “Docs” */'../views/Docs.vue')
  },
  {
    path: '/center-documents',
    name: 'MarkazDocs',
    component: () => import (/* webpackChunkName: “Docs” */'../views/Markazdoc.vue')
  },
  {
    path: '/photos',
    name: 'Photo',
    component: () => import (/* webpackChunkName: “Photo” */'../views/Photos.vue')
  },
  {
    path: '/videos',
    name: 'Video',
    component: () => import (/* webpackChunkName: “Video” */'../views/Video.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import (/* webpackChunkName: “News” */'../views/News.vue')
  },
  {
    path: '/adds',
    name: 'Adds',
    component: () => import (/* webpackChunkName: “Adds” */'../views/Add.vue')
  },
  {
    path: '/404',
    name: 'Error',
    // redirect: '/error-404',
    component: () => import (/* webpackChunkName: “Error” */'../views/error.vue')
  },
 { 
    path: '*', 
    redirect: '/404' 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
