<template>
  <div>
    <!-- <loader2 v-if="load == true"></loader2> -->
    <section class="breadcrumb-outer text-center">
      <div class="container">
        <h2 data-aos="flip-up" class="">
          {{ $t("Header.PressService.News1") }}
        </h2>
      </div>
    </section>
    <section id="mt_blog" class="top-stories">
      <div class="blog_post_sec blog_post_inner">
        <div class="container">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(post, i) in posts"
              :key="i"
              v-if="posts && posts.length > 0 && i <= limit"
            >
              <div class="blog-post_wrapper image-wrapper mar-bottom-30">
                <div class="blog-post-image" style="height: 260px; width: 100%">
                  <img
                    :data-src="
                      'https://backend.scocenter.uz/storage/' + post.image
                    "
                    alt="image"
                    height="100%"
                    class="img-responsive center-block post_img lazyload blur-up"
                  />
                </div>
                <div
                  class="post-content"
                  style="display: flex; flex-flow: row wrap; height: 240px"
                >
                  <h4>
                    <router-link :to="'/post-detail/' + post.id">{{
                      post[`title_${$i18n.locale}`]
                    }}</router-link>
                  </h4>
                  <div
                    class="item-meta"
                    style="width: 100%; align-self: flex-end"
                  >
                    <span style="font-size: 18px; color: #333a65">
                      {{ post.created_at | slice }}
                    </span>
                    <span
                      style="
                        font-size: 18px;
                        margin-right: 40px;
                        color: #333a65;
                        float: right;
                      "
                    >
                      <i class="fa fa-eye" style="margin-right: 7px"></i
                      >{{ post.views_count }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <category></category> -->
  </div>
</template>
<script>
import api from "../apis";

// import Category from '../components/category2'
export default {
  components: {},
  data() {
    return {
      posts: [],
      search: "",
      load: true,
      post1: [],
      pageNumber: 6,
      limit: 8,
    };
  },
  methods: {
    nextPage() {
      this.pageNumber++;
    },
    prevPage() {
      this.pageNumber--;
    },
    async makeRequest() {
      try {
        const res = await api.get("/post").then((response) => {
          setTimeout(() => (this.load = false), 500);
          this.posts = response.data.post;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    filteredBlogs: function () {
      return this.posts.filter(
        (post) => {
          return post[`title_${this.$i18n.locale}`].match(this.search);
          //  this.post1 = post
        }
        //  console.log(this.search)
      );
    },
    pageCount() {
      let l = this.calendar.length,
        s = this.size;
      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = this.pageNumber * this.size,
        end = start + this.size;
      return this.calendar.slice(start, end);
    },
  },
  created() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
:root {
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1920 - 320));
}
body,
button,
input {
  font: 1em Hind, sans-serif;
  line-height: 1.5em;
}
body,
input {
  color: #171717;
}
body,
.search-bar {
  display: flex;
}
body {
  background: #f1f1f1;
  height: 100vh;
}
.search-bar input,
.search-btn,
.search-btn:before,
.search-btn:after {
  transition: all 0.25s ease-out;
}
.search-bar input,
.search-btn {
  width: 3em;
  height: 3em;
}
.search-bar input:invalid:not(:focus),
.search-btn {
  cursor: pointer;
}
.search-bar,
.search-bar input:focus,
.search-bar input:valid {
  width: 100%;
}
.search-bar input:focus,
.search-bar input:not(:focus) + .search-btn:focus {
  outline: transparent;
}
.search-bar {
  margin: auto;
  padding: 1.5em;
  justify-content: center;
  max-width: 30em;
  float: right;
}
.search-bar input {
  background: transparent;
  border-radius: 1.5em;
  box-shadow: 0 0 0 0.4em #171717 inset;
  padding: 0.75em;
  transform: translate(0.5em, 0.5em) scale(0.5);
  transform-origin: 100% 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.search-bar input::-webkit-search-decoration {
  -webkit-appearance: none;
}
.search-bar input:focus,
.search-bar input:valid {
  background: #fff;
  border-radius: 0.375em 0 0 0.375em;
  box-shadow: 0 0 0 0.1em #d9d9d9 inset;
  transform: scale(1);
}
.search-btn {
  background: #171717;
  border-radius: 0 0.75em 0.75em 0 / 0 1.5em 1.5em 0;
  padding: 0.75em;
  position: relative;
  transform: translate(0.25em, 0.25em) rotate(45deg) scale(0.25, 0.125);
  transform-origin: 0 50%;
}
.search-btn:before,
.search-btn:after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
}
.search-btn:before {
  border-radius: 50%;
  box-shadow: 0 0 0 0.2em #f1f1f1 inset;
  top: 0.75em;
  left: 0.75em;
  width: 1.2em;
  height: 1.2em;
}
.search-btn:after {
  background: #f1f1f1;
  border-radius: 0 0.25em 0.25em 0;
  top: 51%;
  left: 51%;
  width: 0.75em;
  height: 0.25em;
  transform: translate(0.2em, 0) rotate(45deg);
  transform-origin: 0 50%;
}
.search-btn span {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 1px;
}
/* Active state */
.search-bar input:focus + .search-btn,
.search-bar input:valid + .search-btn {
  background: #3498db;
  border-radius: 0 0.375em 0.375em 0;
  transform: scale(1);
}
.search-bar input:focus + .search-btn:before,
.search-bar input:focus + .search-btn:after,
.search-bar input:valid + .search-btn:before,
.search-bar input:valid + .search-btn:after {
  opacity: 1;
}
.search-bar input:focus + .search-btn:hover,
.search-bar input:valid + .search-btn:hover,
.search-bar input:valid:not(:focus) + .search-btn:focus {
  background: #3498db;
}
.search-bar input:focus + .search-btn:active,
.search-bar input:valid + .search-btn:active {
  transform: translateY(1px);
}
@media screen and (prefers-color-scheme: dark) {
  body,
  input {
    color: #f1f1f1;
  }
  body {
    background: #171717;
  }
  .search-bar input {
    box-shadow: 0 0 0 0.4em #f1f1f1 inset;
  }
  .search-bar input:focus,
  .search-bar input:valid {
    background: #3d3d3d;
    box-shadow: 0 0 0 0.1em #3d3d3d inset;
  }
  .search-btn {
    background: #f1f1f1;
  }
}
a {
  background: linear-gradient(to right, #333a65, #333a65);
  color: #333a65;
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  background-size: 0px 3px;
  padding-bottom: 10px;
}
a:hover {
  text-decoration: none !important;
  background-size: 100% 3px;
  transition: all ease-in-out 0.5s;
}
@media (max-width: 991px) {
  #mt_blog .col-md-4 {
    height: auto;
  }
}
h2 {
  color: #161b3d;
  margin: 0;
  position: relative;
  font-weight: 800;
  font-size: 22px;
  margin-bottom: 8px;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7 !important;
  max-height: none !important;
}
.bg-orange {
  margin: 0;
  background-color: #3498db !important;
  background-image: none;
}
.breadcrumb-outer {
  padding: 5px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 5px 0 5px !important;
  font-size: 30px;
}
.d-flex {
  display: flex;
  flex-direction: column;
}
#mt_blog .blog-post_wrapper {
  overflow: inherit;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  box-shadow: 0 0 15px #cccccc60;
}
#mt_blog .blog-post_wrapper.image-wrapper:before {
  display: none;
}
#mt_blog .blog-post_wrapper .blog-post-image {
  overflow: hidden;
  position: relative;
}
#mt_blog .blog-post_wrapper .blog-post-image:before {
  content: "";
  background: #00000057;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
#mt_blog .blog-post_wrapper.image-wrapper .post-content {
  position: relative;
  top: inherit;
  bottom: 0;
  padding: 15px;
}
#mt_blog .blog-post_wrapper h3 span {
  font-size: 30px;
  text-transform: uppercase;
  display: block;
}
section#mt_blog.category-list {
  background: url(/images/fitness/sliders/bg9.jpg) no-repeat;
  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;
}
section#mt_blog.category-list:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: #00000070;
  top: 0;
  height: 100%;
  width: 100%;
}
section#mt_blog.category-list .blog-post_wrapper.image-wrapper .post-content {
  background: #fff;
  padding: 15px;
}
section#mt_blog.category-list .row.slider-foods {
  margin: 0 -15px;
}
@media (max-width: 400px) {
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
</style>
