<template>
  <div id="video">
    <div class="container">
      <div class="row" style="margin-bottom: 5px">
        <div
          class="col-md-4"
          style="margin-bottom: 10px; padding: 30px 20px"
          v-for="(video, i) in videos"
          :key="i"
        >
          <!-- video element -->
          <!-- youtube iframe with progressive enhancement (extra queries after the url to optimize the embed) -->
          <vue-plyr
            data-aos="zoom-in-up"
            style="border: 5px solid white; height: 240px"
          >
            <div class="plyr__video-embed">
              <iframe :src="video.video_link"></iframe>
            </div>
          </vue-plyr>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VuePlyr from "vue-plyr";
import api from "../apis";
export default {
  name: "Video",
  components: {
    VuePlyr,
  },
  methods: {},
  data() {
    return {
      load: true,
      videos: {},
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api.get("/video").then((response) => {
          this.videos = response.data.video.slice(0, 3);
        });
        console.log(this.videos);
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    return this.makeRequest();
  },
};
</script>
<style scoped></style>
