<template>
  <div id="app">
    <header-1></header-1>
    <router-view/>
    <footer-1></footer-1>
  </div>
</template>
<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components:{
  'header-1' : Header,
  'footer-1' : Footer
  }
}
</script>
<style  scoped>

</style>

