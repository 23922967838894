<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios'
// import Paginate from 'vuejs-paginate'
// import { INITIAL_EVENTS, createEventId } from './event-utils'
export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data: function() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        events: [],
        // editable: true,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      pageNumber: 0,
       click: false,
      currentEvents: [],
      calendar: []
    }
  },
   props:{
    size:{
      type:Number,
      required:false,
      default: 2
    }
  },
  methods: {
     nextPage(){
         this.pageNumber++;
      },
      prevPage(){
        this.pageNumber--;
      },
         async  Make() {
            axios.get('https://backend.scocenter.uz/api/calendar').then(response => {
             this.calendarOptions.events = [...response.data.calendar.map(item => {
                const data = {
                  title: item.title_uz, 
                  id: item.id,
                  start: item.calendar
                }; return data;
              } )]
            })
          },
          New(){
            axios.get('https://backend.scocenter.uz/api/calendar').then(response => {
              this.click = true
              this.calendar = response.data.calendar
              })
          },
    handleEvents(events) {
      this.calendar = events
    }
  },
      computed:{
      pageCount(){
      let l = this.calendar.length,
          s = this.size;
      return Math.ceil(l/s);
    },
    paginatedData(){
      const start = this.pageNumber * this.size,
            end = start + this.size;
      return this.calendar
               .slice(start, end);
    },
      },
  created(){
    this.Make()
    this.New(),
    this.calendarOptions
  }
  
}
</script>
<template>
  <div class='demo-app  lazyload' data-bgset="/assets/images/Home/calendar6.jpg">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
              <div class='demo-app-sidebar' >
      <div class='demo-app-sidebar-section'>
        <h2 class="white">All Events ({{ calendar.length }})</h2>
        <ul v-if="click == true">
          <li  v-for='event in paginatedData' :key='event.id' class="white">
            <b>{{ event.calendar }}</b>
            <!-- <i>{{ event.title_uz }}</i>
            <br> -->
            <p v-html="event.body_uz" style="color: white !important" ></p>
          </li>
        </ul>
        <!-- <p v-else>
          No avaible date 
        </p> -->
 <div style="display:flex">
                 <button
                  :disabled="pageNumber === 0"
                  @click="prevPage">
                  Previous
              </button>
              <button 
                  style="margin-left: 10px"
                  :disabled="pageNumber >= pageCount -1"
                  @click="nextPage">
                  Next
              </button>
 </div>
      </div>
    </div>
        </div>
        <div class="col-md-9">
              <div class='demo-app-main'>
      <FullCalendar
        class='demo-app-calendar'
        :options='calendarOptions'
      >
        <template v-slot:eventContent='arg'>
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='css' scoped>
button{
  width:100px;
  height:40px;
  background-color:rgb(52, 152, 219);
}

button:hover{
  cursor:pointer;
}
button:hover:disabled{
  cursor:not-allowed;
}
h2 {
  margin: 0;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
li {
  margin: 1.5em 0;
  padding: 0;
}
b { /* used for event dates/times */
  margin-right: 3px;
}
.demo-app {
        background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top center !important;
    background-attachment: fixed;
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 100%;
  margin-top: 40px;
  line-height: 1.5;
  background: transparent;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc .fc-button-group > .fc-button{
  background: blue !important;
}
.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
@media(max-width: 390px){
  .demo-app-sidebar {
  padding: 0;
  width: 200px !important;
}
.demo-app-main {
  flex-grow: 1;
  padding: 0;
  padding-bottom: 30px;
  margin-top: 7px;
}
.demo-app-sidebar-section {
  padding: 0;
}
}
@media(max-width: 340px){
  .demo-app-sidebar {
  padding: 0;
  width: 150px !important;
}
.demo-app-main {
  flex-grow: 1;
  padding: 0;
    padding-bottom: 30px;
  margin-top: 7px;
}
.demo-app-sidebar-section {
  padding: 0;
  width: 200px;
}
}
</style>