<template>
     <section id="mt_banner1" class="">
        <div class="swiper-container" data-aos="zoom-in-up">
            <div class="swiper-wrapper">
                <div class="swiper-slide teaser lazyload" data-expand="-20">
                    <div class="slide-inner half-slide slide-overlay lazyload" data-bgset="/assets/images/Home/indita2.jpg">
                        <div class="banner_caption_text">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.in') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.india }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span> {{Math.round(indiaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                                       <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/astana-marathon-2019-featured.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kazak }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kazakw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                                       </div>
            </div>
                <div class="swiper-slide">
                         <div class="slide-inner half-slide slide-overlay lazyload" data-bgset="/assets/images/Home/devori.jpg">
                        <div class="banner_caption_text">
                                           <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.cn') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.china }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(chinaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload" data-bgset="/assets/images/Home/Kyrgyzstan.png">
                        <div class="banner_caption_text">
                            <div class="post-category">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kg') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kirgiz }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kirgizw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>
                </div>

                <div class="swiper-slide"> 
                   <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/Pakistan.jpg">
                        <div class="banner_caption_text">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.pk') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.pakistan }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(pakistanw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/moscow.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.ru') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.russia }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(russiaw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>

                </div>
                <div class="swiper-slide"> 
 <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/tojikiston.jpg">
                        <div class="banner_caption_text">
                                    <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.tj') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tajik }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tajikw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/city.jpg">
                        <div class="banner_caption_text">
                                       <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.uz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tashkent }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tashkentw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                </div>
                                                                <div class="swiper-slide">
                    <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/india.jpg">
                        <div class="banner_caption_text">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.in') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.india }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span> {{Math.round(indiaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                                       <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/astana.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kazak }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kazakw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                                       </div>
            </div>
                <div class="swiper-slide">
                         <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/china.jpg">
                        <div class="banner_caption_text">
                                           <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.cn') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.china }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(chinaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/bishkek.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kg') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kirgiz }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kirgizw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>
                </div>

                <div class="swiper-slide"> 
                   <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/pakistan2.jpg">
                        <div class="banner_caption_text">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.pk') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.pakistan }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(pakistanw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/rus.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.ru') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.russia }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(russiaw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>

                </div>
                <div class="swiper-slide"> 
 <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/dushanbe.jpg">
                        <div class="banner_caption_text">
                                    <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.tj') }}</span>
                                                <!-- <span>{{Math.round(tajikw)}}*C</span> -->
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tajik }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tajikw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/Home/tashkent2.jpg">
                        <div class="banner_caption_text">
                                       <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.uz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tashkent }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tashkentw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                </div>
                 <div class="swiper-slide">
                    <div class="slide-inner half-slide slide-overlay lazyload" data-bgset="/assets/images/sco/india2.jpg">
                        <div class="banner_caption_text">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.in') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.india }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span> {{Math.round(indiaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                                       <div class="slide-inner pre-half-slide slide-overlay lazyload" data-bgset="/assets/images/sco/kazak2.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kazak }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kazakw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                                       </div>
            </div>
                <div class="swiper-slide">
                         <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/china2.jpg">
                        <div class="banner_caption_text">
                                           <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.cn') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.china }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(chinaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/kirgiz2.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kg') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kirgiz }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kirgizw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>
                </div>

                <div class="swiper-slide"> 
                   <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/pakistan3.jpg">
                        <div class="banner_caption_text">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.pk') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.pakistan }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(pakistanw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/russia2.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.ru') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.russia }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(russiaw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>

                </div>
                <div class="swiper-slide"> 
 <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/tajik2.jpg">
                        <div class="banner_caption_text">
                                    <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.tj') }}</span>
                                                <!-- <span>{{Math.round(tajikw)}}*C</span> -->
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tajik }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tajikw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/uzbek2.jpg">
                        <div class="banner_caption_text">
                                       <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.uz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tashkent }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tashkentw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                </div>
      <div class="swiper-slide">
                    <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/india3.jpg">
                        <div class="banner_caption_text">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.in') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.india }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span> {{Math.round(indiaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                                       <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/kazak1.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kazak }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kazakw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                                       </div>
            </div>
                <div class="swiper-slide">
                         <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/china3.jpg">
                        <div class="banner_caption_text">
                                           <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.cn') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.china }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(chinaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/kirgiz3.jpeg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kg') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kirgiz }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kirgizw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>
                </div>

                <div class="swiper-slide"> 
                   <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/pakistan4.jpg">
                        <div class="banner_caption_text">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.pk') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.pakistan }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(pakistanw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/russia3.jpg" >
                        <div class="banner_caption_text">
                            <div class="post-category">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.ru') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.russia }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(russiaw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>

                </div>
                <div class="swiper-slide"> 
 <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/tajik3.jpg">
                        <div class="banner_caption_text">
                                    <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.tj') }}</span>
                                                <!-- <span>{{Math.round(tajikw)}}*C</span> -->
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tajik }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tajikw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/uzbek3.jpg">
                        <div class="banner_caption_text">
                                       <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.uz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tashkent }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tashkentw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                </div>
      <div class="swiper-slide">
                    <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/india4.jpg">
                        <div class="banner_caption_text">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.in') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.india }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span> {{Math.round(indiaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                                       <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/kazak3.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kazak }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kazakw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                                       </div>
            </div>
                <div class="swiper-slide">
                         <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/china4.jpg">
                        <div class="banner_caption_text">
                                           <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.cn') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.china }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(chinaw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/kirgiz4.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.kg') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.kirgiz }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(kirgizw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>
                </div>

                <div class="swiper-slide"> 
                   <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/pakistan5.jpg">
                        <div class="banner_caption_text">
                              <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.pk') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.pakistan }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(pakistanw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/russia4.jpg">
                        <div class="banner_caption_text">
                            <div class="post-category">
                             <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.ru') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.russia }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(russiaw)}}°C</span>
                                            </div>
                        </div>
                    </div> 
                </div>

                </div>
                <div class="swiper-slide"> 
 <div class="slide-inner half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/tajik4.jpg">
                        <div class="banner_caption_text">
                                    <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.tj') }}</span>
                                                <!-- <span>{{Math.round(tajikw)}}*C</span> -->
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tajik }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tajikw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                    <div class="slide-inner pre-half-slide slide-overlay lazyload blur-up" data-bgset="/assets/images/sco/uzbek5.jpg">
                        <div class="banner_caption_text">
                                       <h3 class="entry-title mar-0">
                                                <span class="white">{{ $t('davlatlar.uz') }}</span>
                                            </h3>
                                            <div class="post-date white" style="font-size: 33px">
                                                <span>{{ time.tashkent }}</span>
                                            </div>
                                            <div class="item-meta white" style="font-size: 28px">
                                                <span>{{Math.round(tashkentw)}}°C</span>
                                            </div>
                        </div>
                    </div>
                </div>
     
            </div>
            <!-- <div class="swiper-button-next swiper-button-"></div>
            <div class="swiper-button-prev swiper-button-"></div> -->
        </div>      
    </section>
</template>
<script scoped>
import axios from 'axios';
var moment = require('moment-timezone');
var moment = require('moment-timezone');
var moment = require('moment-timezone');
var moment = require('moment-timezone');
var moment = require('moment-timezone');
var moment = require('moment-timezone');
var moment = require('moment-timezone');
var moment = require('moment-timezone');
export default {
    name: 'Countries',
      components: {
  },
    data(){
        return{
        // countries:{
        //  name: 'India',
        //  name: 'Kazahkistan',
        //  name: 'China',
        //  name: 'Kyrgyzstan',
        //  name: 'Pakistan',
        //  name: 'Russia',
        //  name: 'Tajikiston',
        //  name: 'Uzbekistan',
        // },
            India: 'Dehli',
            kazak: 'Almaty',
            china: 'Shanghai',
            kirgiz: 'Bishkek',
            pakistan: 'Karachi',
            russia: 'Moscow',
            tajik: 'Dushanbe',
            tashkent: 'Tashkent',
        time:{
            india: {},
            kazak: {},
            china: {},
            kirgiz: {},
            pakistan: {},
            russia: {},
            tajik: {},
            tashkent: {},
        },
            indiaw: {},
            kazakw: {},
            chinaw: {},
            kirgizw: {},
            pakistanw: {},
            russiaw: {},
            tajikw: {},
            tashkentw: {},  
        }
    },
    methods:{

       Weathers: async  function (){
        const key = '44e6b2df9e9515127e413f027b0cf7a1';
        const India =    `https://api.openweathermap.org/data/2.5/weather?q=Dehli&appid=5299e83c9cf2fef510fb8ddaa3206ac8&units=metric`;
        const China =    `https://api.openweathermap.org/data/2.5/weather?q=Shanghai&appid=${key}&units=metric`;
        const Pakistan =    `https://api.openweathermap.org/data/2.5/weather?q=Karachi&appid=${key}&units=metric`;
        const Kazakistan =    `https://api.openweathermap.org/data/2.5/weather?q=Almaty&appid=${key}&units=metric`;
        const Tajikistan =    `https://api.openweathermap.org/data/2.5/weather?q=Dushanbe&appid=${key}&units=metric`;
        const Russia =    `https://api.openweathermap.org/data/2.5/weather?q=Moscow&appid=${key}&units=metric`;
        const Krgizistan =    `https://api.openweathermap.org/data/2.5/weather?q=Bishkek&appid=${key}&units=metric`;
        const Uzbekistan =    `https://api.openweathermap.org/data/2.5/weather?q=Tashkent&appid=${key}&units=metric`;
        //india
        
        axios.get(India).then(
            response => {
        this.indiaw = response.data.main.temp
            }
        )
       // China//
        axios.get(China).then(
            response => {
        this.chinaw = response.data.main.temp
            }
        )

              // Pakistan//
        axios.get(Pakistan).then(
            response => {
        this.pakistanw = response.data.main.temp
            }
        )
       
              // Kazakistan//
        axios.get(Kazakistan).then(
            response => {
        this.kazakw = response.data.main.temp
            }
        )
       
              // Krgizistan//
        axios.get(Krgizistan).then(
            response => {
        this.kirgizw = response.data.main.temp
            }
        )

              // Russia//
        axios.get(Russia).then(
            response => {
        this.russiaw = response.data.main.temp
            }
        )

              // Tajikistan//
        axios.get(Tajikistan).then(
            response => {
        this.tajikw = response.data.main.temp
            }
        )
   
              // Uzbekistan//
        axios.get(Uzbekistan).then(
            response => {
        this.tashkentw = response.data.main.temp
            }
        )

       },
       
    },
    

    created(){
this.Weathers()
 this.time.india   = moment().tz( "Asia/Kolkata").format('HH:mm')
 this.time.kazak   = moment().tz( "Asia/Almaty").format('HH:mm')
 this.time.china   = moment().tz( "Asia/Shanghai").format('HH:mm')
 this.time.pakistan   = moment().tz( "Asia/Karachi").format('HH:mm')
 this.time.kirgiz   = moment().tz( "Asia/Bishkek").format('HH:mm')
 this.time.tajik   = moment().tz( "Asia/Dushanbe").format('HH:mm')
 this.time.russia   = moment().tz( "Europe/Moscow").format('HH:mm')
 this.time.tashkent   = moment().tz( "Asia/Tashkent").format('HH:mm')
 
    }
}
</script>
<style scoped>
#mt_banner1 {
    position: relative;
    padding: 0;
    height: 450px!important;
}

 #mt_banner1 .half-slide, #mt_banner .pre-half-slide{
  width: 50%;
  top: 0;
  left: 0;
  border-radius: 0;
  overflow: hidden; 
  border: 0px;
  border: none;
}

#mt_banner1 .pre-half-slide{
  left: 50%;
}

#mt_banner1 .pre-half-slide .banner_caption_text, #mt_banner .half-slide .banner_caption_text{
  width: 90%;
  position: relative;
} 

@media(max-width: 767px){
  #mt_banner1 .half-slide{width: 100%;}
  #mt_banner1 .pre-half-slide{display: none;}

  #mt_banner1 .half-slide .banner_caption_text{width: 80%;}
}
.slide-inner {
    position: absolute;
    width: calc(50% - 0px);
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
}
.slide-inner:before {
    content: '';
    /* background: ; */
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    /* z-index: -1; */
}
.banner_caption_text{
    margin-left: 20px;
    margin-top: 15px;
        position: relative;
}
.swiper-button-prev:before, .swiper-container-rtl .swiper-button-next:before {
    content: '\f104';
    left: 15px;
    right: inherit;
    background: transparent;
    color: white;
}
.swiper-button-next:before{
    content: '\f105';
    font-family: 'fontawesome';
    font-size: 54px;
    position: absolute;
    right: 15px;
    color: white;
    background: transparent;
    padding: 0 18px;
}
</style>