<template>
    <div >
<div class="show1" >
      <section id="mt_banner2" class="">
        <div data-aos="zoom-in-up" class="swiper-container " >
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flagin.jpg" style="display: flex; justify-content: center">
                    <div class="map">
                      <img data-src="/assets/images/state/india2.png" class="lazyload"  alt="">
                    </div>
                    <div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.in') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> New Delhi
                                <br>
                                <strong>Region:</strong> Southern Asia
                                <br>
                                <strong>Language:</strong> Hindi, English
                                <br>
                                <strong>Population:</strong> 1.29521B
                                <br>
                                <strong>Area:</strong>  3.28759M km²
                                <br>
                                <strong>Currency:</strong>  Indian rupee ₹
                                <br>
                                <strong>World Bank Gini:</strong> 33.4%
                                <br>
                                <strong>Lat Long:</strong> Lat: 20, Lng: 77
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/India" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Нью-Дели.
                                <br>
                                <strong>Регион:</strong> Южная Азия
                                <br>
                                <strong>Язык:</strong> хинди, английский
                                <br>
                                <strong>Население:</strong> 1.29521B
                                <br>
                                <strong>Площадь:</strong>  3.28759M km²
                                <br>
                                <strong>Валюта:</strong>  Indian rupee ₹
                                <br>
                                <strong>Джини Всемирного банка:</strong> 33.4%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%98%D0%BD%D0%B4%D0%B8%D1%8F" class="white" target="_blank" rel="noopener noreferrer">более</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Nyu-Dehli
                                <br>
                                <strong>Mintaqa:</strong> Janubiy Osiyo
                                <br>
                                <strong>Til:</strong> hind, ingliz
                                <br>
                                <strong>Aholisi:</strong> 1.29521B
                                <br>
                                <strong>Maydon:</strong>  3.28759M km²
                                <br>
                                <strong>Valyuta:</strong>  Indian rupee ₹
                                <br>
                                <strong>Jahon banki Gini:</strong> 33.4%
                                <br>
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Hindiston" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong> Ню-Деҳли
                                <br>
                                <strong>Минтақа:</strong> Жанубий Осиё
                                <br>
                                <strong>Тил:</strong> ҳинд, инглиз
                                <br>
                                <strong>Aҳолиси:</strong> 1.29521B
                                <br>
                                <strong>Майдон:</strong>  3.28759M km²
                                <br>
                                <strong>Валюта:</strong>  Indian rupee ₹
                                <br>
                                <strong>Жаҳон банки Гини:</strong> 33.4%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Hindiston" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide"> 
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flagkz.jpg"  style="display: flex; justify-content: center">
                                            <div class="map">
                      <img data-src="/assets/images/state/kazak.png"  class="lazyload" alt="">
                    </div>
                         <div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.kz') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> Astana
                                <br>
                                <strong>Region:</strong> Central Asia
                                <br>
                                <strong>Language:</strong> Kazakh, Russian
                                <br>
                                <strong>Population:</strong> 17.7532M
                                <br>
                                <strong>Area:</strong>  2.7249M km²
                                <br>
                                <strong>Currency:</strong>  Kazakhstani tenge 
                                <br>
                                <strong>World Bank Gini:</strong>  29%
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/Kazakhstan" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Астана.
                                <br>
                                <strong>Регион:</strong> Центральная Азия
                                <br>
                                <strong>Язык:</strong> казахский, русский
                                <br>
                                <strong>Население:</strong> 17,7532 млн
                                <br>
                                <strong>Площадь:</strong>  2,7249 млн км².
                                <br>
                                <strong>Валюта:</strong>  казахстанский тенге
                                <br>
                                <strong>Джини Всемирного банка:</strong> 29%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD" class="white" target="_blank" rel="noopener noreferrer">более</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Ostona
                                <br>
                                <strong>Mintaqa:</strong> Markaziy Osiyo
                                <br>
                                <strong>Til:</strong> qozoq, rus
                                <br>
                                <strong>Aholisi:</strong> 17.7532M
                                <br>
                                <strong>Maydon:</strong>  2.7249M km²
                                <br>
                                <strong>Valyuta:</strong>  Qozog'iston tangasi
                                <br>
                                <strong>Jahon banki Gini:</strong> 29%
                                <br>
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Qozog%CA%BBiston" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong> Остона
                                <br>
                                <strong>Минтақа:</strong> Марказий Осиё
                                <br>
                                <strong>Тил:</strong> қозоқ, рус
                                <br>
                                <strong>Aҳолиси:</strong> 17.7532М
                                <br>
                                <strong>Майдон:</strong>  2.7249М км²
                                <br>
                                <strong>Валюта:</strong>  Қозоғистон тангаси
                                <br>
                                <strong>Жаҳон банки Гини:</strong> 29%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Qozog%CA%BBiston" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide"> 
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flagchn.jpg"  style="display: flex; justify-content: center">
                                                                       <div class="map">
                      <img data-src="/assets/images/state/cn.png"  class="lazyload" alt="">
                    </div>
                           <div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.cn') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> Beijing
                                <br>
                                <strong>Region:</strong> Eastern Asia
                                <br>
                                <strong>Language:</strong> Chinese
                                <br>
                                <strong>Population:</strong> 1.377422166B
                                <br>
                                <strong>Area:</strong>  9.640011M km²
                                <br>
                                <strong>Currency:</strong>  Chinese yuan ¥
                                <br>
                                <strong>World Bank Gini:</strong>  47%
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/China" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Пекин.
                                <br>
                                <strong>Регион:</strong> Восточная Азия
                                <br>
                                <strong>Язык:</strong>китайский
                                <br>
                                <strong>Население:</strong> 1.377422166B
                                <br>
                                <strong>Площадь:</strong>  9,640011 млн км².
                                <br>
                                <strong>Валюта:</strong>  китайский юань ¥
                                <br>
                                <strong>Джини Всемирного банка:</strong>47%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9" class="white" target="_blank" rel="noopener noreferrer">более</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Pekin
                                <br>
                                <strong>Mintaqa:</strong> Sharqiy Osiyo
                                <br>
                                <strong>Til:</strong> xitoy
                                <br>
                                <strong>Aholisi:</strong> 1.377422166B
                                <br>
                                <strong>Maydon:</strong>  9.640011M km²
                                <br>
                                <strong>Valyuta:</strong>  Xitoy yuani ¥
                                <br>
                                <strong>Jahon banki Gini:</strong> 47%
                                <br>
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Xitoy" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong> Пекин
                                <br>
                                <strong>Минтақа:</strong> Шарқий Осиё
                                <br>
                                <strong>Тил:</strong> хитой
                                <br>
                                <strong>Aҳолиси:</strong> 1.377422166Б
                                <br>
                                <strong>Майдон:</strong>  9.640011М км²
                                <br>
                                <strong>Валюта:</strong>  Хитой юани ¥
                                <br>
                                <strong>Жаҳон банки Гини:</strong> 47%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Xitoy" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
                                <div class="swiper-slide"> 
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flagkr.jpg" style="display: flex; justify-content: center">
                    <div class="map">
                      <img data-src="/assets/images/state/kg.png"  class="lazyload" alt="">
                    </div>
                         <div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.kg') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> Bishkek
                                <br>
                                <strong>Region:</strong> Central Asia
                                <br>
                                <strong>Language:</strong> Kyrgyz, Russian
                                <br>
                                <strong>Population:</strong> 6.0478M
                                <br>
                                <strong>Area:</strong>  199.951K km²
                                <br>
                                <strong>Currency:</strong>  Kyrgyzstani som
                                <br>
                                <strong>World Bank Gini:</strong>  36.2%
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/Kyrgyzstan" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Бишкек.
                                <br>
                                <strong>Регион:</strong> Центральная Азия
                                <br>
                                <strong>Язык:</strong> кыргызский, русский
                                <br>
                                <strong>Население:</strong> 6.0478M
                                <br>
                                <strong>Площадь:</strong>  199,951 тыс. Км².
                                <br>
                                <strong>Валюта:</strong>  киргизский сом с
                                <br>
                                <strong>Джини Всемирного банка:</strong> 36,2%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%9A%D0%B8%D1%80%D0%B3%D0%B8%D0%B7%D0%B8%D1%8F" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Bishkek
                                <br>
                                <strong>Mintaqa:</strong> Markaziy Osiyo
                                <br>
                                <strong>Til:</strong> qirg'iz, rus
                                <br>
                                <strong>Aholisi:</strong> 6.0478M
                                <br>
                                <strong>Maydon:</strong>  199.951K km²
                                <br>
                                <strong>Valyuta:</strong>  Qirg'iziston so'mi s
                                <br>
                                <strong>Jahon banki Gini:</strong> 36,2%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Qirg%CA%BBiziston" class="white" target="_blank" rel="noopener noreferrer">Ko'rish</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong>  Бишкек
                                <br>
                                <strong>Минтақа:</strong> Марказий Осиё
                                <br>
                                <strong>Тил:</strong> қирғиз, рус
                                <br>
                                <strong>Aҳолиси:</strong> 6.0478М
                                <br>
                                <strong>Майдон:</strong>  199.951К км²
                                <br>
                                <strong>Валюта:</strong>  Қирғизистон сўми с
                                <br>
                                <strong>Жаҳон банки Гини:</strong>  36,2%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Qirg%CA%BBiziston" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
                                                <div class="swiper-slide"> 
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flagpk.jpg" style="display: flex; justify-content: center">
                    <div class="map">
                      <img data-src="/assets/images/state/pk.png" class="lazyload" alt="">
                    </div>
                       <div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.pk') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> Islamabad
                                <br>
                                <strong>Region:</strong> Southern Asia
                                <br>
                                <strong>Language:</strong> English, Urdu
                                <br>
                                <strong>Population:</strong> 194.125062M
                                <br>
                                <strong>Area:</strong>  881.912K km²
                                <br>
                                <strong>Currency:</strong>  Pakistani rupee ₨
                                <br>
                                <strong>World Bank Gini:</strong>  30%
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/Pakistan" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Исламабад.
                                <br>
                                <strong>Регион:</strong> Южная Азия
                                <br>
                                <strong>Язык:</strong> английский, урду
                                <br>
                                <strong>Население:</strong> 194,125062 млн
                                <br>
                                <strong>Площадь:</strong>  881,912 тыс. Км².
                                <br>
                                <strong>Валюта:</strong>  пакистанская рупия ₨
                                <br>
                                <strong>Джини Всемирного банка:</strong> 30%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%9F%D0%B0%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD" class="white" target="_blank" rel="noopener noreferrer">более</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Islomobod
                                <br>
                                <strong>Mintaqa:</strong> Janubiy Osiyo
                                <br>
                                <strong>Til:</strong> ingliz, urdu
                                <br>
                                <strong>Aholisi:</strong> 194.125062M
                                <br>
                                <strong>Maydon:</strong>  881.912K km²
                                <br>
                                <strong>Valyuta:</strong>  Pokiston rupiyasi ₨
                                <br>
                                <strong>Jahon banki Gini:</strong> 30%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Pokiston" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong>  Исломобод
                                <br>
                                <strong>Минтақа:</strong> Жанубий Осиё
                                <br>
                                <strong>Тил:</strong> инглиз, урду
                                <br>
                                <strong>Aҳолиси:</strong> 194.125062М
                                <br>
                                <strong>Майдон:</strong>  881.912К км²
                                <br>
                                <strong>Валюта:</strong>  Покистон рупияси ₨
                                <br>
                                <strong>Жаҳон банки Гини:</strong>  30%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Pokiston" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
                                                <div class="swiper-slide"> 
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flagru.jpg" style="display: flex; justify-content: center">
                    <div class="map">
                      <img data-src="/assets/images/state/ru.png" class="lazyload" alt="">
                    </div>
                           <div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.ru') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> Moscow
                                <br>
                                <strong>Region:</strong> Eastern Europe
                                <br>
                                <strong>Language:</strong> Russian
                                <br>
                                <strong>Population:</strong> 146.599183M
                                <br>
                                <strong>Area:</strong>  17.124442M km²
                                <br>
                                <strong>Currency:</strong> Russian ruble ₽
                                <br>
                                <strong>World Bank Gini:</strong> 40.1%
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/Russia" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Москва.
                                <br>
                                <strong>Регион:</strong> Восточная Европа
                                <br>
                                <strong>Язык:</strong> русский
                                <br>
                                <strong>Население:</strong> 146,599183 млн
                                <br>
                                <strong>Площадь:</strong>  17,124442 млн км².
                                <br>
                                <strong>Валюта:</strong>  российский рубль ₽
                                <br>
                                <strong>Джини Всемирного банка:</strong> 40,1%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D1%8F" class="white" target="_blank" rel="noopener noreferrer">более</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Moskva
                                <br>
                                <strong>Mintaqa:</strong> Sharqiy Evropa
                                <br>
                                <strong>Til:</strong> rus tili
                                <br>
                                <strong>Aholisi:</strong> 146.599183M
                                <br>
                                <strong>Maydon:</strong>  17.124442M km²
                                <br>
                                <strong>Valyuta:</strong>  Rossiya rubli ₽
                                <br>
                                <strong>Jahon banki Gini:</strong> 40,1%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Rossiya" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong>  Москва
                                <br>
                                <strong>Минтақа:</strong> Шарқий Европа
                                <br>
                                <strong>Тил:</strong> рус тили
                                <br>
                                <strong>Aҳолиси:</strong>  146.599183М
                                <br>
                                <strong>Майдон:</strong>  17.124442М км²
                                <br>
                                <strong>Валюта:</strong>  Россия рубли ₽
                                <br>
                                <strong>Жаҳон банки Гини:</strong>  40,1%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Rossiya" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
                                                <div class="swiper-slide"> 
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flagtj.jpg" style="display: flex; justify-content: center">
                    <div class="map">
                      <img data-src="/assets/images/state/tj1.png" class="lazyload" alt="">
                    </div>
<div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.tj') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> Dushanbe
                                <br>
                                <strong>Region:</strong> Central Asia
                                <br>
                                <strong>Language:</strong> Tajik, Russian
                                <br>
                                <strong>Population:</strong> 8.5936M
                                <br>
                                <strong>Area:</strong>  143.1K km²
                                <br>
                                <strong>Currency:</strong>  Tajikistani somoni ЅМ
                                <br>
                                <strong>World Bank Gini:</strong>  30.8%
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/Tajikistan" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Душанбе.
                                <br>
                                <strong>Регион:</strong> Центральная Азия
                                <br>
                                <strong>Язык:</strong> таджикский, русский
                                <br>
                                <strong>Население:</strong> 8,5936 млн
                                <br>
                                <strong>Площадь:</strong>  143,1 тыс. Км².
                                <br>
                                <strong>Валюта:</strong> таджикский сомони ЅМ
                                <br>
                                <strong>Джини Всемирного банка:</strong> 30,8%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%A2%D0%B0%D0%B4%D0%B6%D0%B8%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD" class="white" target="_blank" rel="noopener noreferrer">более</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Dushanbe
                                <br>
                                <strong>Mintaqa:</strong> Markaziy Osiyo
                                <br>
                                <strong>Til:</strong> tojik, rus
                                <br>
                                <strong>Aholisi:</strong> 8.5936M
                                <br>
                                <strong>Maydon:</strong>  143.1K km²
                                <br>
                                <strong>Valyuta:</strong>  Tojikiston somonisi ЅM
                                <br>
                                <strong>Jahon banki Gini:</strong> 30,8%
                                <br>
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Tojikiston" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong> Душанбе
                                <br>
                                <strong>Минтақа:</strong> Марказий Осиё
                                <br>
                                <strong>Тил:</strong> тожик, рус
                                <br>
                                <strong>Aҳолиси:</strong> 8.5936М
                                <br>
                                <strong>Майдон:</strong>  143.1К км²
                                <br>
                                <strong>Валюта:</strong>   Тожикистон сомониси ЅМ
                                <br>
                                <strong>Жаҳон банки Гини:</strong> 30,8%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/Tojikiston" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
                                                <div class="swiper-slide"> 
                    <div class="slide-inner full-slide slide-overlay lazyload" data-bgset="/assets/images/Home/flaguz.jpg" style="display: flex; justify-content: center">
                    <div class="map">
                      <img data-src="/assets/images/state/uz.png" class="lazyload" alt="">
                    </div>
<div class="banner_caption_text">
                            <h1 class="white">{{ $t('davlatlar.uz') }}</h1>
                            <p v-if="$i18n.locale == 'en'" class="white">
                                <strong>Capital:</strong> Tashkent
                                <br>
                                <strong>Region:</strong> Central Asia
                                <br>
                                <strong>Language:</strong> Uzbek, Russian
                                <br>
                                <strong>Population:</strong> 31.5764M
                                <br>
                                <strong>Area:</strong>  447.4K km²
                                <br>
                                <strong>Currency:</strong>  Uzbekistani so'm
                                <br>
                                <strong>World Bank Gini:</strong>  36.7%
                                <br>
                                 <strong><a href="https://en.wikipedia.org/wiki/Uzbekistan" class="white" target="_blank" rel="noopener noreferrer">More</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'ru'" class="white">
                                <strong>Столица:</strong> Ташкент.
                                <br>
                                <strong>Регион:</strong> Центральная Азия
                                <br>
                                <strong>Язык:</strong> узбекский, русский
                                <br>
                                <strong>Население:</strong> 31,5764 млн
                                <br>
                                <strong>Площадь:</strong>  447,4 тыс. Км².
                                <br>
                                <strong>Валюта:</strong> узбекский соьм
                                <br>
                                <strong>Джини Всемирного банка:</strong> 36,8%
                                <br>
                                 <strong><a href="https://ru.wikipedia.org/wiki/%D0%A3%D0%B7%D0%B1%D0%B5%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD" class="white" target="_blank" rel="noopener noreferrer">более</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'uz'" class="white">
                                <strong>Poytaxti: </strong> Toshkent
                                <br>
                                <strong>Mintaqa:</strong> Markaziy Osiyo
                                <br>
                                <strong>Til:</strong> o'zbek, rus
                                <br>
                                <strong>Aholisi:</strong> 31.5764M
                                <br>
                                <strong>Maydon:</strong>  447,4 km km²
                                <br>
                                <strong>Valyuta:</strong>  O'zbekiston so'm
                                <br>
                                <strong>Jahon banki Gini:</strong> 36,8%
                                <br>
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/O%CA%BBzbekiston" class="white" target="_blank" rel="noopener noreferrer">Batafsil</a></strong>
                                </p>
                                <p v-if="$i18n.locale == 'cyril'" class="white">
                                <strong>Пойтахти:</strong> Тошкент
                                <br>
                                <strong>Минтақа:</strong> Марказий Осиё
                                <br>
                                <strong>Тил:</strong> ўзбек, рус
                                <br>
                                <strong>Aҳолиси:</strong> 31.5764М
                                <br>
                                <strong>Майдон:</strong>  447,4 км км²
                                <br>
                                <strong>Валюта:</strong>   Ўзбекистон сўм
                                <br>
                                <strong>Жаҳон банки Гини:</strong> 36,8%
                                <br>
                                 <strong><a href="https://uz.wikipedia.org/wiki/O%CA%BBzbekiston" class="white" target="_blank" rel="noopener noreferrer">Кўриш</a></strong>
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </section>
</div>
    </div>
</template>
<script scoped>
export default {
    name: "Sco",
            data() {
          return{
          }
        },
        methods:{
slider(){
    let interleaveOffset = 0.5;
        const swipera = new Swiper(".swiper-container",
       {
  		loop: true,
  		speed: 3000,
  		grabCursor: false,
  		watchSlidesProgress: true,
  		mousewheelControl: true,
  		keyboardControl: true,
  		autoplay: true,
  		navigation: {
  		},
  		autoplay: {
   	 		delay: 2050,
  		},
  		fadeEffect: {
    		crossFade: true
  		},
  		on: {
    		progress: function() {
      			let swipera = this;
      			for (var i = 0; i < swipera.slides.length; i++) {
        			var slideProgress = swipera.slides[i].progress;
        			var innerOffset = swipera.width * interleaveOffset;
        			var innerTranslate = slideProgress * innerOffset;
        			swipera.slides[i].querySelector(".slide-inner").style.transform =
        			"translate3d(" + innerTranslate + "px, 0, 0)";
      			}
    		},
    		touchStart: function() {
      			let swipera = this;
      			for (var i = 0; i < swipera.slides.length; i++) {
        			swipera.slides[i].style.transition = "";
      			}
    		},
    		setTransition: function(speed) {
      			let swipera = this;
      			for (var i = 0; i < swipera.slides.length; i++) {
        			swipera.slides[i].style.transition = speed + "ms";
        			swipera.slides[i].querySelector(".slide-inner").style.transition =
        			speed + "ms";
      			}
    		}
  		}
	})
}
        },
    created(){
      this.slider()
    }
}
</script>
<style scoped>
#mt_banner2 {
    position: relative;
    padding: 0;
    height: 535px !important;
    margin-top: 0px;
}
.slide-inner:before {
    content: '';
    /* background: #0000002b; */
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    /* z-index: -1; */
}

 #mt_banner2 .full-slide{
  width: 100%;
  top: 0;
  left: 0%;
  overflow: hidden; 
}
#mt_banner2 .banner_caption_text[data-v-658eadd3] {
    width: 60%;
    margin-bottom: 20%;
     top: 15%;
    float: right;
    text-align: center;
    
    position: relative;
    /* font-size: 9px; */
}
img{
      height: 500px;
    /* width: 100%; */
    margin-left: 10%;
}
@media(max-width: 600px){
img{
  margin-top: 35%;
      height: 300px;
    /* width: 100%; */
    margin-left: 10%;
}
}
 h1{
  font-size: 40px  !important; 
  color: white !important;
}
#mt_banner2 .banner_caption_text{width: 60%; bottom: 28%;}

#mt_banner2 .banner_caption_text h1{font-size: 48px;}

@media(max-width: 1100px){
  #mt_banner2 .banner_caption_text{width: 60%;}
  #mt_banner2 .banner_caption_text h1{font-size: 36px;}
}

@media(max-width: 811px){
  #mt_banner2 .banner_caption_text{top: 14%;}
}

@media(max-width: 639px){
  #mt_banner2 .banner_caption_text{width: 90%;}
}

@media(max-width: 400px){
  h1{
    font-size: 33px !important;
  }
  #mt_banner2{
    height: 445px !important;
  }
  #mt_banner2 .banner_caption_text{top: 10%;}
}
@media(max-width: 550px){
  .map{
    display: none;
  }
}

/* ============================= */
/*          blog post        */
/* ============================= */
section#mt_blog.category-list .blog_post_inner {
    margin-top: -120px;
    z-index: 1;
    position: relative;
}
section#mt_blog.category-list .blog-post_wrapper{
  border:5px solid #fff;
}
#mt_banner2 .banner_caption_text {
    width: 60%;
    bottom: 28%;
    width: 60%;
    margin-bottom: 20%;
    top: 15%;
    float: right;
    text-align: center;
    position: relative;
}
</style>