<template>
    <div>
    <section class="breadcrumb-outer text-center bg-orange">
        <div class="container">
                <h2 data-aos="zoom-in-up">{{ $t('Header.Contact') }}</h2>
        </div>
    </section>
    <!-- BreadCrumb Ends -->

    <div class="jjjjj" data-aos="zoom-in-up" style="height: 400px">
          <gmaps-map :options="mapOptions">
    <gmaps-marker  :position="{ lat: 41.287757, lng: 69.254156 }" />
  </gmaps-map>
    </div>

    <section class="contact">
        <div class="container">
            <div class="row">
                <div class="col-md-8" data-aos="zoom-in-up">
                    <h1>{{ $t('Main.send') }}</h1>
                    <div id="contact-form" class="contact-form"> 
                           
                        <div id="contactform-error-msg"></div>

                        <form action="reset" name="contactform" id="contactform">
                            <div class="row">
                                <div class="form-group col-xs-12">
                                    <label>{{ $t('contact.name') }}:</label>
                                    <input type="text" name="full_name" class="form-control" id="Name" required>
                                </div>
                                <div class="form-group col-xs-6">
                                    <label>{{ $t('contact.email') }}:</label>
                                    <input type="email" name="email" class="form-control" id="email" placeholder="abc@xyz.com" required>
                                </div>
                                <div class="form-group col-xs-6 col-left-padding">
                                    <label>{{ $t('contact.phone') }}:</label>
                                    <input type="text" name="phone" class="form-control" id="phnumber" placeholder="XXXX-XXXXXX" required>
                                </div>
                                <div class="form-group textarea col-xs-12">
                                    <label>{{ $t('contact.message') }}:</label>
                                    <textarea name="comments" required></textarea>
                                </div>
                                <div class="col-xs-12">
                                    <div class="comment-btn">
                                         <input type="reset" class="btn-blog" id="submit" :value=" $t('Main.send')">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-4" data-aos="zoom-in-up">
                    <div class="contact-about footer-margin">
                        <div class="about-logo mar-bottom-20">
                            <img src="/assets/images/logoo.webp" alt="Image" style="    margin-top: 25px; height: 137px; width: 137px;">
                        </div>
                        <div class="contact-location">
                            <ul class="d-flex">
                                <li><i class="fa fa-map-marker" aria-hidden="true"></i> {{ $t('Main.adres') }}</li>
                                <li><i class="fa fa-phone"></i> +998 71 202-08-60/62</li>
                                <li><i class="fa fa-phone"></i>+998 71 202-08-61</li>                                         
                                <li><i class="fa fa-envelope"></i> <a href="mailto:info@csco.uz">info@scocenter.uz</a></li>
                            </ul>
                        </div>
                        <div class="follow_us">
                            <ul class="social_icons">
                                <li><a href="https://www.facebook.com/Scocenteruz/" target="_blank"> <i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://twitter.com/admin_scocenter" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/scocentreuz/" target="_blank" ><i class="fa fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UC60Sr1891-7qCIkjD1_12dA" target="_blank" ><i class="fa fa-youtube"></i></a></li>
                                <li><a href="https://t.me/scocenteruz"><i class="fa fa-paper-plane-o" target="_blank" ></i></a></li>
                            </ul>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
export default {
    name: 'Contact',
     components: { gmapsMap, gmapsMarker },
        data: () => ({
      mapOptions: {
        center: { lat: 41.2874609, lng: 69.253753 },
        zoom: 17,
      },
    }),
}
</script>
<style scoped>
.bg-orange{
    margin: 0;
    background-color: #3498DB !important;
    background-image: none;
}
section {
    padding: 40px 0 !important;
    position: relative;
}
.breadcrumb-outer h2{
    padding: 70px 0 15px !important;
}
@media(max-width: 400px){
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
.d-flex{
    display: flex;
    flex-direction: column;
}
</style>