import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import x5GMaps from "x5-gmaps";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag";
import axios from "axios";
import VueAxios from "vue-axios";
import Loader from "./components/loader.vue";
import Loader2 from "./components/loader2.vue";
import AOS from "aos";
import VueMeta from "vue-meta";

import "aos/dist/aos.css";
Vue.use(VueAxios, axios);
Vue.component("loader", Loader);
Vue.component("loader2", Loader2);
Vue.component("country-flag", CountryFlag);
Vue.use(x5GMaps, { key: "AIzaSyB9TjZ738gEUOCYu0YJSjvSu18eVFJAHkQ" });
Vue.config.productionTip = false;
Vue.filter("slice", function(value) {
  return value.slice(0, 10) + ".";
});
Vue.use(VueMeta, {
  keyName: "head",
});
Vue.filter("kes", function(value) {
  return value.slice(0, 160) + "..";
});
new Vue({
  created() {
    AOS.init();
  },
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
Vue.use(VuePlyr, {
  plyr: {},
});
