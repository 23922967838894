<template>
  <div>
    <section style="padding-top: 100px">
      <h1 data-aos="zoom-in-up" class="text-center">{{ $t("Main.scomem") }}</h1>
      <sco></sco>
    </section>
    <section class="news1Style">
      <news1> </news1>
      <div style="display: flex; justify-content: center">
        <router-link :to="'/news'" class="btn">{{
          $t("Main.more")
        }}</router-link>
      </div>
    </section>

    <section style="padding-bottom: 30px">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div data-aos="zoom-in-up" class="breaking_news">
              <div class="breaking_inner">
                <h3 class="mar-0 white">{{ $t("Main.hikmat") }}</h3>
                <marquee
                  behavior="scroll"
                  direction="left"
                  v-for="(hikmat, i) in hikmatli"
                  :key="i"
                  onMouseOver="this.stop()"
                  onMouseOut="this.start()"
                >
                  <span>{{ hikmat[`title_${$i18n.locale}`] }} </span>
                </marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
      <cate></cate>
    </section>

    <!-- <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12" style="margin-top: 30px">
            <h1 data-aos="zoom-in-up" class="text-center">
              {{ $t("Main.calendar") }}
            </h1>
            <calendar-1></calendar-1>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section style="padding-top: 0">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <h1
              data-aos="zoom-in-up"
              class="text-center"
              style="margin-top: 10px; color: #3498db !important"
            >
              {{ $t("Main.members") }}
            </h1>
            <map-sco></map-sco>
          </div>
        </div>
      </div>
    </section> -->
    <section style="padding-top: 0">
      <h1 data-aos="zoom-in-up" class="text-center mar-top-15">
        {{ $t("Header.PressService.Photo") }}
      </h1>
      <photo-2></photo-2>
      <div style="display: flex; justify-content: center; margin-bottom: 30px">
        <router-link :to="'/photos'" class="btn">{{
          $t("Main.more")
        }}</router-link>
      </div>
    </section>
    <section style="padding-top: 0" class="mar-bottom-50">
      <h1 data-aos="zoom-in-up" class="text-center mar-top-40">
        {{ $t("Header.PressService.Video") }}
      </h1>
      <video-1 style="background: #3498db"></video-1>
      <div style="display: flex; justify-content: center; margin-top: 10px">
        <router-link :to="'/videos'" class="btn">{{
          $t("Main.more")
        }}</router-link>
      </div>
    </section>
    <!-- <loader2 v-if="load == true"></loader2> -->
  </div>
</template>

<script>
import Slider from "../components/Slider";
import Calendar from "../components/Calendar";
import Photo from "../components/MPhoto";
import Video from "../components/Video";
import MapSco from "../components/MapSco.vue";
import Cate from "../components/category2";
import sco from "../components/ScoCountries";
import api from "../apis";
import News1 from "../components/NewsHome";
import News from "./News.vue";

// import Footer from '../components/Footer'
export default {
  name: "Home",
  metaInfo: {
    title:
      "Xalq Diplomatiyasi markazi, Халқ Дипломатияси маркази, Center for Public Diplomacy",
    titleTemplate: "%s | Центр народной дипломатии",
  },
  components: {
    Slider,
    "calendar-1": Calendar,
    "photo-2": Photo,
    "video-1": Video,
    MapSco,
    Cate,
    sco,
    News1,
    News,
    // 'footer-1' : Footer
    // FullPage,
  },
  data() {
    return {
      // options: {
      //   licenseKey: 'YOUR_KEY_HERE',
      //   afterLoad: this.afterLoad,
      //   scrollOverflow: true,
      //   scrollBar: false,
      //   menu: '#menu',
      //   navigation: true,
      //   anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7',],
      // }
      load: true,
      hikmatli: "",
    };
  },
  methods: {
    async makeRequest() {
      this.load = true;
      try {
        const res = await api.get("/word").then((response) => {
          setTimeout(() => (this.load = false), 200),
            (this.hikmatli = response.data.word.slice(0, 1));
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
* {
  text-transform: uppercase;
}
h1 {
  color: #3498db;
}
.btn {
  background: #3498db;
  color: white;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 17px;
  border-radius: 64px !important;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.news1Style {
  margin-top: -150px !important;
}

.breaking_news {
  position: relative;
  font-size: 22px;
  margin-top: 0;
  /* background: cyan; */
}
.widget-title {
  background: linear-gradient(45deg, #3498db, #3498db);
  padding: 12px 15px;
}
.slick-slide img {
  height: 133px;
}
#mt_blog .blog-post_wrapper .blog-post-image img {
  height: 100%;
}
.widget-popular-post .widget-posts {
  padding: 15px;
}
.widget-popular-post .widget-posts:nth-child(even) {
  background: #fbfbfb;
}
.widget-popular-post .post-thumb {
  float: left;
  max-width: 125px;
  margin-right: 15px;
}
.widget-popular-post .post-thumb img {
  border-radius: 0 10px 0 10px;
}
.widget-popular-post .post-title {
  text-align: left;
}
.widget.widget-popular-post .post-title h4 {
  margin-bottom: 0;
  font-size: 13px;
}
.widget-category-list {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  margin-top: 14px;
}
.breaking_news h3 {
  position: absolute;
  padding: 13px 15px;
  background: #3498db;
  border-radius: 0 0px 0 10px;
  z-index: 1;
  top: 0;
  left: 0;
}
@media (max-width: 639px) {
  .breaking_news marquee {
    padding: 65px 0px 0;
    margin: 0 20px;
  }
  .breaking_news h3 {
    width: 100%;
  }
  h1 {
    font-size: 22px;
  }
}
</style>
