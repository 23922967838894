<div class="col-md-4 col-sm-12" data-aos="zoom-in-up">
                        <div class="blog-post-wrapper mar-top-10" 
                        >
                            <div class="section-title">
                                <h2>{{ $t('Main.cate') }}</h2>
                            </div> 
                                <div class="widget-category">
                                    <ul class="widget-category-list">
                                        <li><router-link :to="'/news-category/2'">{{  $t('Tabs.cat1') }}</router-link></li>
                                        <li><router-link :to="'/news-category/3'">{{  $t('Tabs.cat2') }}</router-link></li>
                                        <li><router-link :to="'/news-category/4'">{{  $t('Tabs.cat3') }}</router-link></li>
                                        <!-- <li><router-link :to="'/news-category/5'">{{  $t('Tabs.cat4') }}</router-link></li> -->
                                        <li><router-link :to="'/news-category/6'">{{  $t('Tabs.cat5') }}</router-link></li>
                                        <li><router-link :to="'/news-category/7'">{{  $t('Tabs.cat6') }}</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
<template>
  <div class="home-default">
    <!--*Header*-->
    <header id="inner-navigation">
      <!-- navbar start -->
      <nav
        class="navbar navbar-default navbar-fixed-top navbar-sticky-function navbar-arrow"
      >
        <div class="container">
          <div class="logo pull-left">
            <h1>
              <router-link :to="'/'"
                ><img width="50px" src="/assets/images/logoo.webp"
              /></router-link>
            </h1>
          </div>
          <li class="display-none">
            <div :v-model="$i18n.locale" class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                style="display: flex; align-items: center"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <country-flag :country="$i18n.locale" />
                <country-flag v-if="$i18n.locale == 'cyril'" :country="'uz'" />
                <country-flag v-if="$i18n.locale == 'en'" :country="'gb'" />
                <i
                  class="fa fa-angle-down"
                  style="font-size: 25px; color: black"
                ></i>
              </button>
              <div
                class="dropdown-menu"
                style="margin: 0 8px; background: #3498DB; padding: 2px 10px 0px 5px;"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  v-for="(lang, i) in langs"
                  :key="`Lang${i}`"
                  :value="lang.name"
                  @click="$i18n.locale = lang.name"
                  class="dropdown-item"
                >
                  <country-flag
                    :country="`${lang.flag}`"
                    :value="country.flag"
                  />
                  <span>{{ lang.laq }}</span>
                </a>
              </div>
            </div>
          </li>
          <div id="navbar" class="navbar-nav-wrapper text-center">
            <ul class="nav navbar-nav navbar-right" id="responsive-menu">
              <li>
                <router-link :to="'/'"> {{ $t("Header.Home") }}</router-link>
              </li>

              <li>
                <router-link :to="''"
                  >{{ $t("Main.cate") }}<i class="fa fa-angle-down"></i
                ></router-link>
                <ul>
                  <li>
                    <router-link :to="'/news-category/2'">{{
                      $t("Tabs.cat1")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/news-category/3'">{{
                      $t("Tabs.cat2")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/news-category/4'">{{
                      $t("Tabs.cat3")
                    }}</router-link>
                  </li>
                  <!-- <li>
                    <router-link :to="'/news-category/5'">{{
                      $t("Tabs.cat4")
                    }}</router-link>
                  </li> -->
                  <li>
                    <router-link :to="'/news-category/6'">{{
                      $t("Tabs.cat5")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/news-category/7'">{{
                      $t("Tabs.cat6")
                    }}</router-link>
                  </li>
                  <!-- <li><router-link :to="'/'">{{ $t('Header.AboutUs.Vacancies') }}</router-link></li> -->
                </ul>
              </li>
              <li>
                <router-link :to="''"
                  >{{ $t("Header.AboutUs.AboutUs")
                  }}<i class="fa fa-angle-down"></i
                ></router-link>
                <ul>
                  <li>
                    <router-link :to="'/about-center'">{{
                      $t("Header.AboutUs.Center")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/manual'">{{
                      $t("Header.AboutUs.Manual")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/structure'">{{
                      $t("Header.AboutUs.Structur")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/personnel'">{{
                      $t("Header.AboutUs.Structure")
                    }}</router-link>
                  </li>
                  <!-- <li><router-link :to="'/'">{{ $t('Header.AboutUs.Vacancies') }}</router-link></li> -->
                </ul>
              </li>
              <li>
                <router-link :to="''"
                  >{{ $t("Header.Documents.Documents") }}
                  <i class="fa fa-angle-down"></i
                ></router-link>
                <ul>
                  <li>
                    <router-link :to="'/documents'">{{
                      $t("Header.Documents.Constituent")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/center-documents'">{{
                      $t("Header.Documents.Center")
                    }}</router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link :to="''"
                  >{{ $t("Header.PressService.Press") }}
                  <i class="fa fa-angle-down"></i
                ></router-link>
                <ul>
                  <li>
                    <router-link :to="'/news'">{{
                      $t("Header.PressService.News")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/videos'">{{
                      $t("Header.PressService.Video")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/photos'">{{
                      $t("Header.PressService.Photo")
                    }}</router-link>
                  </li>
                  <!-- <li>
                    <router-link :to="'#'">{{
                      $t("Header.PressService.Paper")
                    }}</router-link>
                  </li> -->
                  <li>
                    <a
                      href="https://old.scocenter.uz/category/novosti-i-sobytiya/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ $t("Header.PressService.Arxiv") }}</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <router-link :to="'/adds'">{{ $t("Main.elon") }}</router-link>
                <!-- <ul>
                                <li ><router-link :to="'/'">{{ $t('Header.Online.Questions') }}</router-link></li>
                                <li><router-link :to="'/'">{{ $t('Header.Online.Polls') }}</router-link></li>
                        </ul> -->
              </li>
              <li>
                <router-link :to="'/contact'">{{
                  $t("Header.Contact")
                }}</router-link>
              </li>
            </ul>
          </div>
          <!--/.nav-collapse -->
        </div>
        <div id="slicknav-mobile"></div>
      </nav>
      <!-- navbar end -->
    </header>
    <!--* End Header*-->
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      langs: [
        { name: "uz", flag: "uz", laq: "UZ" },
        { name: "ru", flag: "ru", laq: "RU" },
        { name: "en", flag: "gb", laq: "EN" },
        { name: "cyril", flag: "uz", laq: "KR" },
      ],
      country: "",
    };
  },
  mounted() {
    return this.langs;
    /*======== Doucument Ready Function =========*/
  },
};
</script>
<style scoped>
* {
  text-transform: uppercase;
}
.dropdown-item {
  color: white !important;
  display: flex;
  align-items: center;
}
ul .hover {
  display: none;
}
ul :hover .hover {
  display: block;
}
.slicknav_btn.slicknav_open:before,
.slicknav_btn.slicknav_collapsed:before {
  font-size: 30px !important;
  /* color: #333a65; */
}
@media (max-width: 991px) {
  #inner-navigation .navbar {
    /* background: #fff;
    margin: 0; */
    padding: 40px 0 !important;
    /* border: none; */
  }
}
#inner-navigation .navbar.navbar-sticky-in .logo img {
  width: 67%;
  transition: all ease-in-out 0.5s;
}
#inner-navigation .navbar {
  background: white;
  color: black;
}
li a {
  color: black !important;
}
#inner-navigation .navbar {
  background: #fff;
  margin: 0;
  padding: 15px 0 !important;
  position: fixed;
  border: none;
}
#inner-navigation
  .navbar.navbar-default
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a,
#inner-navigation .nav.navbar-nav > li > a {
  color: black;
}
.slicknav_nav a {
  padding: 10px 20px;
  margin: 0;
  text-decoration: none;
  color: #b6b3c4 !important;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
}
#inner-navigation
  .navbar.navbar-sticky-in
  .navbar-collapse
  ul.nav.navbar-nav
  li
  a,
#inner-navigation .navbar.navbar-sticky-in .nav.navbar-nav > li > a {
  color: #777;
  font-size: 17px;
}
.dropdown-menu {
  min-width: auto !important;
  text-align: center;
}
.btn {
  padding: 0;
  background: transparent !important;
}

.display-block {
  display: none;
}
.display-none {
  list-style: none;
  float: right;
  margin-top: 10px;
  margin-left: 22px;
}
@media (max-width: 992px) {
  .display-none {
    margin-top: 18px;
    margin-right: 31px;
  }
}
</style>
