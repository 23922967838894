<template>
  <div>
    <!--PRELOADER-->
    <!--*Footer*-->
    <footer
      id="mt_footer"
      class="lazyload"
      data-bgset="/assets/images/Home/mainbg.jpg"
    >
      <div class="container">
        <div class="mt_footer_col" data-aos="zoom-in-up">
          <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="mt_footer_about">
                <h2>
                  <a href="#" class="white">{{ $t("Main.sco") }}</a>
                </h2>
                <p class="white" style="font-size: 20px">
                  {{ $t("Shanghai") }}
                </p>
                <div class="follow_us mar-top-20">
                  <ul class="social_icons">
                    <li>
                      <a
                        href="https://www.facebook.com/Scocenteruz/"
                        target="_blank"
                      >
                        <i class="fa fa-facebook"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/admin_scocenter"
                        target="_blank"
                        ><i class="fa fa-twitter"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/scocentreuz/"
                        target="_blank"
                        ><i class="fa fa-instagram"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UC60Sr1891-7qCIkjD1_12dA"
                        target="_blank"
                        ><i class="fa fa-youtube"></i
                      ></a>
                    </li>
                    <li>
                      <a href="https://t.me/scocenteruz"
                        ><i class="fa fa-paper-plane-o" target="_blank"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="mt_footer_list">
                <h3 class="white">{{ $t("Main.quick") }}</h3>
                <ul
                  class="footer-quick-links-4"
                  style="display: flex; flex-direction: column"
                >
                  <li>
                    <router-link :to="'/about-center'">{{
                      $t("Header.AboutUs.Center")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/manual'">{{
                      $t("Header.AboutUs.Manual")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/structure'">{{
                      $t("Header.AboutUs.Structur")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="'/personnel'">{{
                      $t("Header.AboutUs.Structure")
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="mt_footer_newsletter">
                <h3 class="white">{{ $t("Main.contact") }}</h3>
                <ul class="d-flex white">
                  <li class="white">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    {{ $t("Main.adres") }}
                  </li>
                  <br />
                  <li class="white">
                    <i class="fa fa-phone"></i> +998 71 202-08-60/62
                  </li>
                  <br />
                  <li class="white">
                    <i class="fa fa-phone"></i>+998 71 202-08-61
                  </li>
                  <br />
                  <li class="white">
                    <i class="fa fa-envelope white"></i>
                    <a target="_blank" class="white" href="mailto:info@csco.uz"
                      >info@scocenter.uz</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="mt_footer_newsletter">
                <!-- <h3 class="white">Contacts</h3> -->
                <br />
                <br />
                <ul class="d-flex white">
                  © 2021
                  {{
                    $t("Shanghai")
                  }}
                  - scocenter.uz
                  <br />
                  <br />
                  {{
                    $t("Main.huquq")
                  }}
                  <br />
                  <br />
                  {{
                    $t("Main.napa")
                  }}
                  <a
                    href="https://napaautomotive.uz/"
                    target="_blank"
                    class="white"
                    style="font-weight: bold"
                    rel="noopener noreferrer"
                    >NAPA Automative</a
                  >
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- <div id="preloader1">
        <div class="lazyload" data-bgset="/assets/images/loader.webm" id="status1"></div>
    </div> -->
    <!--* End Footer*-->
    <!-- <loader></loader> -->
    <!-- 
    back to top -->
    <a
      id="back-to-top"
      href="#"
      class="btn btn-primary btn-lg back-to-top"
      role="button"
      title=""
      data-placeHolidayst="left"
    >
      <span class="fa fa-arrow-up"></span>
    </a>

    <!-- search popup -->
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
#mt_footer {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center !important;
  background-attachment: fixed;
}
.mt_instagram {
  position: relative;
}
.mt_instagram .sectio-title {
  position: absolute;
  z-index: 1;
  background: #fff;
  padding: 15px 25px;
  bottom: 28%;
  left: 40%;
  border: 1px solid #333a65;
  border-radius: 26px;
}
.slick-slide img {
  height: 133px;
}
.mt_instagram .col-sm-2 {
  padding: 0 5px 0;
  width: 12.5%;
}
.mt_instagram .row {
  margin: 0 -5px;
}
.mt_instagram img {
  border-radius: 0 26px;
  transition: all ease-in-out 0.5s;
}
.follow_us .social_icons li {
  margin-bottom: 0;
  padding-right: 10px;
}
.mt_instagram img:hover {
  filter: grayscale(100%);
  transition: all ease-in-out 0.5s;
}
@media (max-width: 991px) {
  .mt_instagram {
    text-align: center;
  }
  .mt_instagram .sectio-title {
    position: inherit;
    bottom: inherit;
    left: inherit;
    display: inline-block;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .mt_instagram img {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .mt_instagram .sectio-title h3 {
    font-size: 18px;
  }
}
</style>